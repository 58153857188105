import React from "react";
import "./FormRegistration.css";
import { Link } from "react-router-dom";
import { send } from "emailjs-com";
import { useState, useEffect } from "react";
import { ListPays as countries, typeFormation, civilitie, HandicapBolean, formatLabel} from "../../../consts";
// material-ui
import {
  Button,
  Grid,
  Card,
  Divider,
  TextField,
  CardContent,
  CardActions,
  MenuItem,
  Select,
  FormControl,
  ListItemText,
  InputLabel,
  CardHeader,
  FormHelperText,
  Snackbar,
  TextareaAutosize,
  
} from "@mui/material";
import {Alert as MuiAlert} from "@mui/material";

const question1 = "Êtes-vous une personne en situation de handicap ? ";
const question2 = "Quel type de Handicap avez-vous ? ";
const question3 ="Comment avez-vous connu notre Académie ? ";

const FormRegistration = () => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [projet, setProjet] = useState("");
  const [fonction, setFonction] = useState("");
  const [handicap, setHandicap] = useState("");
  const [typeHandicap, setTypeHandicap] = useState("");
  const [civilities, setCivilities] = useState("");
  const [employeur, setEmployeur] = useState("");
  const [tel, setTel] = useState("");
  const [pays, setPays] = useState("");
  const [sourceFinance, setSourceFinance] = useState("");
  const [motivation, setMotivation] = useState("");
  const [academie, setAcademie] = useState("");
  const [formation, setFormation] = useState("");
  const [show, setShow] = useState(false);
  const [toSend, setToSend] = useState({});
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [open, setOpen] = useState(false);
  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  //* HANDLE FUNCTIONS

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };
  const handleChangeFirstName = (event) => {
    setFirstName(event.target.value);
  };
  const handleChangeLastName = (event) => {
    setLastName(event.target.value);
  };
  useEffect(() => {
    if (handicap === true) {
      setShow(true);
    } else {
      setShow(false);
    }
  }, [handicap]);
  const handleChangeHandicap = (event) => {
    setHandicap(event.target.value);
  };

  const handleChangeEmail = (event) => {
    setEmail(event.target.value);
  };
  const handleChangeProjet = (event) => {
    setProjet(event.target.value);
  };
  const handleChangeFonction = (event) => {
    setFonction(event.target.value);
  };
  const handleChangeTypeHandicap = (event) => {
    setTypeHandicap(event.target.value);
  };
  const handleChangeCivilities = (event) => {
    setCivilities(event.target.value);
  };
  const handleChangeEmployeur = (event) => {
    setEmployeur(event.target.value);
  };
  const handleChangeTel = (event) => {
    setTel(event.target.value);
  };
  const handleChangePays = (event) => {
    setPays(event.target.value);
  };
  const handleChangeSourceFinance = (event) => {
    setSourceFinance(event.target.value);
  };
  const handleChangeMotivation = (event) => {
    setMotivation(event.target.value);
  };
  const handleChangeAcademie = (event) => {
    setAcademie(event.target.value);
  };
  const handleChangeFormation = (event) => {
    setFormation(event.target.value);
  };
  const onSubmit = (e) => {
    e.preventDefault();
    if (
      lastName !== "" &&
      firstName !== "" &&
      email !== "" &&
      fonction !== "" &&
      projet !== "" &&
      handicap !== "" &&
      civilities !== "" &&
      employeur !== "" &&
      tel !== "" &&
      pays !== "" &&
      formation !== "" &&
      academie !== ""
    ) {
      // console.log(toSend);

      setToSend({
        Nom: lastName,
        Prénom: firstName,
        Email: email,
        Fonction: fonction,
        Projet: projet,
        "Handicap ou non": handicap,
        "Quel type de Handicap avez-vous ?": typeHandicap,
        Civilitie: civilities,
        Employeur: employeur,
        Téléphone: tel,
        Pays: pays,
        "Source de financement": sourceFinance,
        Formation: formation,
        "Vos attentions": motivation,
        "Comment avez-vous connu notre Académie ?": academie,
      });
      send("service_83leksg", "template_ypkhcd5", toSend, "MFGSQV0lbpsTorw8q")
        .then((response) => {
          console.log("SUCCESS!", response.status, response.text);
          setSuccess(true);
        })
        .catch((err) => {
          console.log("FAILED...", err);
        });
      setError(false);
    } else {
      setError(true);
    }
  };
  

  return (
    <>
      {/* <Particles params={ParticleConfig}></Particles> */}
      <div className="containerform">
        <div className="picture">
          <div className="bg"></div>
          <div className="bg bg2"></div>
          <div className="bg bg3"></div>
          <p>Vivez une expérience unique !</p>
          <Link to="/">
            <button className="btn-retour">Retour</button>
          </Link>
        </div>
        <div className="form">
          <Grid className="formulaire" container>
            <Grid item xs={12}>
              <Card>
                <CardHeader title="Formulaire d'inscription" inscription />
                <CardContent>
                  <Grid container spacing={3} alignItems="center">
 		    <Grid item xs={12} lg={12}>
                      <InputLabel
                        style={{ color: "black", marginBottom: "2px" }}
                        id="demo-simple-select-standard-label"
                      >
                         {formatLabel("Formation ")} 
                      </InputLabel>
                      {error === true && formation === "" ? (
                        <>
                          <FormControl error fullWidth>
                            <Select
                              id="demo-simple-select"
                              value={formation}
                              onChange={handleChangeFormation}
                            >
                              {typeFormation.map((element) => (
                                <MenuItem
                                  key={element.key}
                                  value={element.value}
                                >
                                  <ListItemText primary={element.label} />
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                          <FormHelperText>
                            Ce champs est obligatoire*
                          </FormHelperText>
                        </>
                      ) : (
                        <FormControl fullWidth>
                          <Select
                            id="demo-simple-select"
                            value={formation}
                            onChange={handleChangeFormation}
                          >
                            {typeFormation.map((element) => (
                              <MenuItem key={element.key} value={element.value}>
                                <ListItemText primary={element.label} />
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      )}
                    </Grid>
                    <Grid item xs={12} lg={6}>
                      <InputLabel
                        style={{ color: "black", marginBottom: "2px" }}
                        id="demo-simple-select-standard-label"
                      >
                         {formatLabel("Nom ")}
                      </InputLabel>
                      {error === true && lastName === "" ? (
                        <>
                          <TextField
                            fullWidth
                            error
                            required
                            placeholder="Nom"
                            value={lastName}
                            onChange={handleChangeLastName}
                          />
                          <FormHelperText>Ce champs est obligatoire*</FormHelperText>
                        </>
                      ) : (
                        <TextField
                          fullWidth
                          required
                          placeholder="Nom"
                          value={lastName}
                          onChange={handleChangeLastName}
                        />
                      )}
                    </Grid>
                    <Grid item xs={12} lg={6}>
                      <InputLabel
                        style={{ color: "black", marginBottom: "2px" }}
                        id="demo-simple-select-standard-label"
                      >
                         {formatLabel("Prénom ")}
                      </InputLabel>
                      {error === true && firstName === "" ? (
                        <>
                          <TextField
                            fullWidth
                            error
                            required
                            placeholder="Prénom"
                            value={firstName}
                            onChange={handleChangeFirstName}
                          />
                          <FormHelperText>
                            Ce champs est obligatoire*
                          </FormHelperText>
                        </>
                      ) : (
                        <TextField
                          fullWidth
                          required
                          placeholder="Prénom"
                          value={firstName}
                          onChange={handleChangeFirstName}
                        />
                      )}
                    </Grid>
                    <Grid item xs={12} lg={6}>
                      <InputLabel
                        style={{ color: "black", marginBottom: "2px" }}
                        id="demo-simple-select-standard-label"
                      >
                         {formatLabel("Email ")} 
                      </InputLabel>
                      {error === true && email === "" ? (
                        <>
                          <TextField
                            fullWidth
                            error
                            required
                            placeholder="ex. aid@example.com"
                            value={email}
                            onChange={handleChangeEmail}
                          />
                          <FormHelperText>
                            Ce champs est obligatoire*
                          </FormHelperText>
                        </>
                      ) : (
                        <TextField
                          fullWidth
                          required
                          placeholder="ex. aid@example.com"
                          value={email}
                          onChange={handleChangeEmail}
                        />
                      )}
                    </Grid>
                    <Grid item xs={12} lg={6}>
                      <InputLabel
                        style={{ color: "black", marginBottom: "2px" }}
                        id="demo-simple-select-standard-label"
                      >
                        {formatLabel("Fonction ")}  
                      </InputLabel>
                      {error === true && fonction === "" ? (
                        <>
                          <TextField
                            fullWidth
                            error
                            required
                            placeholder=" ex. PMO Manager"
                            value={fonction}
                            onChange={handleChangeFonction}
                          />
                          <FormHelperText>
                            Ce champs est obligatoire*
                          </FormHelperText>
                        </>
                      ) : (
                        <TextField
                          fullWidth
                          required
                          placeholder=" ex. PMO Manager"
                          value={fonction}
                          onChange={handleChangeFonction}
                        />
                      )}
                    </Grid>
                    <Grid item xs={12} lg={6}>
                      <InputLabel
                        style={{ color: "black", marginBottom: "2px" }}
                        id="demo-simple-select-standard-label"
                      >
                        {formatLabel("Employeur (Ministère/Organisation) ")}  
                      </InputLabel>
                      {error === true && employeur === "" ? (
                        <>
                          <TextField
                            fullWidth
                            error
                            required
                            placeholder="ex. Ministère de  l'Énergie"
                            value={employeur}
                            onChange={handleChangeEmployeur}
                          />
                          <FormHelperText>
                            Ce champs est obligatoire*
                          </FormHelperText>
                        </>
                      ) : (
                        <TextField
                          fullWidth
                          required
                          placeholder="ex. Ministère de  l'Énergie"
                          value={employeur}
                          onChange={handleChangeEmployeur}
                        />
                      )}
                    </Grid>
                    <Grid item xs={12} lg={6}>
                      <InputLabel
                        style={{ color: "black", marginBottom: "2px" }}
                        id="demo-simple-select-standard-label"
                      >
                           {formatLabel("Projet ")}  
                      </InputLabel>
                      {error === true && projet === "" ? (
                        <>
                          <TextField
                            fullWidth
                            error
                            required
                            placeholder="ex. Projet de Digitalisation des Paiements Gouvernementaux "
                            value={projet}
                            onChange={handleChangeProjet}
                          />
                          <FormHelperText>
                            Ce champs est obligatoire*
                          </FormHelperText>
                        </>
                      ) : (
                        <TextField
                          fullWidth
                          required
                          placeholder="ex. Projet de Digitalisation des Paiements Gouvernementaux "
                          value={projet}
                          onChange={handleChangeProjet}
                        />
                      )}
                    </Grid>

                    <Grid item xs={12} lg={6}>
                      <InputLabel
                        style={{ color: "black", marginBottom: "2px" }}
                        id="demo-simple-select-standard-label"
                      >
                         {formatLabel("Civilité ")}   
                      </InputLabel>
                      {error === true && civilities === "" ? (
                        <>
                          <FormControl fullWidth error>
                            <Select
                              id="demo-simple-select"
                              value={civilities}
                              onChange={handleChangeCivilities}
                            >
                              {civilitie.map((element) => (
                                <MenuItem
                                  key={element.key}
                                  value={element.value}
                                >
                                  <ListItemText primary={element.label} />
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                          <FormHelperText>
                            Ce champs est obligatoire*
                          </FormHelperText>
                        </>
                      ) : (
                        <FormControl fullWidth>
                          <Select
                            id="demo-simple-select"
                            value={civilities}
                            onChange={handleChangeCivilities}
                          >
                            {civilitie.map((element) => (
                              <MenuItem key={element.key} value={element.value}>
                                <ListItemText primary={element.label} />
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      )}
                    </Grid>
                    <Grid item xs={12} lg={6}>
                      <InputLabel
                        style={{ color: "black", marginBottom: "2px" }}
                        id="demo-simple-select-standard-label"
                      >
                        {formatLabel("Téléphone ")}   
                      </InputLabel>
                      {error === true && tel === "" ? (
                        <>
                          <TextField
                            fullWidth
                            error
                            required
                            placeholder=" Ex. 00216 "
                            inputProps={{
                              inputMode: "numeric",
                              pattern: "[0-9]*",
                            }}
                            value={tel}
                            onChange={handleChangeTel}
                          />
                          <FormHelperText>
                            Ce champs est obligatoire*
                          </FormHelperText>
                        </>
                      ) : (
                        <TextField
                          fullWidth
                          required
                          placeholder=" Ex. 00216 "
                          value={tel}
                          onChange={handleChangeTel}
                        />
                      )}
                    </Grid>
                    <Grid item xs={12} lg={6}>
                      <InputLabel
                        style={{ color: "black", marginBottom: "2px" }}
                        id="demo-simple-select-standard-label"
                      >
                         {formatLabel("Pays ")}    
                      </InputLabel>
                      {error === true && pays === "" ? (
                        <>
                          <FormControl fullWidth error>
                            <Select
                              id="demo-simple-select"
                              value={pays}
                              onChange={handleChangePays}
                            >
                              {countries.map((element, i) => (
                                <MenuItem
                                  key={i+1}
                                  value={element}
                                >
                                  <ListItemText primary={element} />
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                          <FormHelperText>
                            Ce champs est obligatoire*
                          </FormHelperText>
                        </>
                      ) : (
                        <FormControl fullWidth>
                          <Select
                            id="demo-simple-select"
                            value={pays}
                            onChange={handleChangePays}
                          >
                            {countries.map((element,i) => (
                              <MenuItem key={i+1} value={element}>
                                <ListItemText primary={element} />
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      )}
                    </Grid>
                   
                    <Grid item xs={12} lg={6}>
                      <InputLabel
                        style={{ color: "black", marginBottom: "2px" }}
                        id="demo-simple-select-standard-label"
                      >
                         {formatLabel("Source de financement ")} 
                      </InputLabel>
                      {error === true && sourceFinance === "" ? (
                        <>
                          <TextField
                            fullWidth
                            error
                            required
                            placeholder="ex. Banque Mondiale"
                            value={sourceFinance}
                            onChange={handleChangeSourceFinance}
                          />
                          <FormHelperText>
                            Ce champs est obligatoire*
                          </FormHelperText>
                        </>
                      ) : (
                        <TextField
                          fullWidth
                          required
                          placeholder="ex. Banque Mondiale"
                          value={sourceFinance}
                          onChange={handleChangeSourceFinance}
                        />
                      )}
                    </Grid>

                    <Grid item xs={12} lg={12}>
                      <InputLabel
                        style={{ color: "black", marginBottom: "2px" }}
                        id="demo-simple-select-standard-label"
                      >
                        {formatLabel(question1)}
                      </InputLabel>
                      {error === true && handicap === "" ? (
                        <>
                          <FormControl error fullWidth>
                            <Select
                              id="demo-simple-select"
                              value={handicap}
                              onChange={handleChangeHandicap}
                            >
                              {HandicapBolean.map((element) => (
                                <MenuItem
                                  key={element.key}
                                  value={element.value}
                                >
                                  <ListItemText primary={element.label} />
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                          <FormHelperText>
                            Ce champs est obligatoire*
                          </FormHelperText>
                        </>
                      ) : (
                        <FormControl fullWidth>
                          <Select
                            id="demo-simple-select"
                            value={handicap}
                            onChange={handleChangeHandicap}
                          >
                            {HandicapBolean.map((element) => (
                              <MenuItem key={element.key} value={element.value}>
                                <ListItemText primary={element.label} />
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      )}
                    </Grid>
                    {show && (
                      <Grid item xs={12} lg={12}>
                        <InputLabel
                          style={{ color: "black", marginBottom: "2px" }}
                          id="demo-simple-select-standard-label"
                        >
                        {formatLabel(question2)}
                        </InputLabel>
                        {error === true && typeHandicap === "" ? (
                          <>
                            <TextField
                              fullWidth
                              error
                              required
                              placeholder="Notre référent Handicap vous contactera pour considérer vos besoins spécifiques dans l’organisation de la formation"
                              value={typeHandicap}
                              onChange={handleChangeTypeHandicap}
                            />
                            <FormHelperText>
                              Ce champs est obligatoire*
                            </FormHelperText>
                          </>
                        ) : (
                          <TextField
                            fullWidth
                            required
                            placeholder="Notre référent Handicap vous contactera pour considérer vos besoins spécifiques dans l’organisation de la formation"
                            value={typeHandicap}
                            onChange={handleChangeTypeHandicap}
                          />
                        )}
                      </Grid>
                    )}

                    <Grid item xs={12} lg={12}>
                      <InputLabel
                        style={{ color: "black", marginBottom: "2px" }}
                        id="demo-simple-select-standard-label"
                      >
                       {formatLabel(question3)}
                      </InputLabel>
                      {error === true && academie === "" ? (
                        <>
                          <TextField
                            fullWidth
                            error
                            required
                            placeholder=" Ex. LinkedIn                         "
                            value={academie}
                            onChange={handleChangeAcademie}
                          />
                        </>
                      ) : (
                        <TextField
                          fullWidth
                          required
                          placeholder=" Ex. LinkedIn                       "
                          value={academie}
                          onChange={handleChangeAcademie}
                        />
                      )}
                    </Grid>
                    <Grid item xs={12} lg={12}>
                      <InputLabel
                        style={{ color: "black", marginBottom: "0.5em" }}
                        id="demo-simple-select-standard-label"
                      >
                        Vos attentes
                      </InputLabel>
                      <TextareaAutosize
                        minRows={3}
                        style={{ color: "black", marginBottom: "0.5em", width:"99%"}}
                        placeholder="Vos motivations"
                        value={motivation}
                        onChange={handleChangeMotivation}
                      />
                    </Grid>
                  </Grid>
                </CardContent>
                <Divider style={{ marginTop: "30px" }} />
                <CardActions>
                  <Grid container spacing={2}>
                    <Grid item>
                      <Button
                        style={{
                          marginTop: "10px",
                          marginBottom: "10px",
                          backgroundColor: "#270268",
                        }}
                        variant="contained"
                        color="secondary"
                        onClick={onSubmit}
                      >
                        Envoyer
                      </Button>
                    </Grid>
                  </Grid>
                </CardActions>{" "}
              </Card>
            </Grid>
          </Grid>
          {success && (
            <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
              <Alert
                onClose={handleClose}
                severity="success"
                sx={{ width: "100%" }}
              >
                Merci pour votre inscription!
              </Alert>
            </Snackbar>
          )}
        </div>
      </div>
    </>
  );
};

export default FormRegistration;
