export const ListPays = [
  "Afrique du Sud",
  "Afghanistan",
  "Albanie",
  "Algérie",
  "Allemagne",
  "Andorre",
  "Angola",
  "Arabie saoudite",
  "Argentine",
  "Arménie",
  "Australie",
  "Autriche",
  "Azerbaïdjan",
  "Bahamas",
  "Bahreï",
  "Bangladesh",
  "Barbade",
  "Bélarus",
  "Belgique",
  "Bélize",
  "Bénin",
  "Bhoutan",
  "Birmanie",
  "Bolivie",
  "Bosnie-Herzégovine",
  "Botswana",
  "Brésil",
  "Brunéi Darussalam",
  "Bulgarie",
  "Burkina Faso",
  "Burundi",
  "Cameroun",
  "Canada",
  "Chili",
  "Chine",
  "Chypre",
  "Colombie",
  "Comores",
  "Congo",
  "Corée du Nord",
  "Corée du Sud",
  "Costa Rica",
  "Côte d’Ivoire",
  "Croatie",
  "Cuba",
  "Danemark",
  "Djibouti",
  "Dominique",
  "Égypte",
  "Émirats arabes unis",
  "Équateur",
  "Érythrée",
  "Espagne",
  "Estonie",
  "États-Unis d’Amérique",
  "Éthiopie",
  "Fidji",
  "Finlande",
  "France",
  "Gabon",
  "Gambie",
  "Géorgie",
  "Ghana",
  "Grèce",
  "Grenade",
  "Guatemala",
  "Guinée",
  "Guinée-Bissau",
  "Guinée équatoriale",
  "Guyana",
  "Haïti",
  "Honduras",
  "Hongrie",
  "Île Maurice",
  "Îles Marshall",
  "Îles Salomon",
  "Inde",
  "Indonésie",
  "Iran",
  "Iraq",
  "Irlande",
  "Islande",
  "Italie",
  "Jamaïque",
  "Japon",
  "Jordanie",
  "Kazakhstan",
  "Kenya",
  "Kiribati",
  "Kirghizistan",
  "Kosovo",
  "Koweït",
  "Laos",
  "Lesotho",
  "Lettonie",
  "Liban",
  "Libéria",
  "Libye",
  "Liechtenstein",
  "Lituanie",
  "Luxembourg",
  "Macédoine",
  "Madagascar",
  "Malaisie",
  "Malawi",
  "Maldives",
  "Mali",
  "Malte",
  "Maroc",
  "Mauritanie",
  "Mexique",
  "Micronésie",
  "Moldavie",
  "Monaco",
  "Monténégro",
  "Mozambique",
  "Namibie",
  "Nauru",
  "Népal",
  "Nicaragua",
  "Niger",
  "Nigéria",
  "Norvège",
  "Nouvelle-Zélande",
  "Oman",
  "Ouganda",
  "Ouzbékistan",
  "Pakistan",
  "Palaos",
  "Panamá",
  "Papouasie-Nouvelle-Guinée",
  "Paraguay",
  "Pays-Bas",
  "Pérou",
  "Philippines",
  "Pologne",
  "Portugal",
  "Qatar",
  "République centrafricaine",
  "République démocratique du Congo",
  "République dominicaine",
  "République tchèque",
  "Roumanie",
  "Royaume-Uni",
  "Russie",
  "Rwanda",
  "Saint-Kitts-et-Nevis",
  "Sainte-Lucie",
  "Saint-Marin",
  "Saint-Vincent-et-les Grenadines",
  "Saint-Siège",
  "Salvador",
  "Samoa",
  "Sao Tomé-et-Principe",
  "Sénégal",
  "Serbie",
  "Seychelles",
  "Sierra Leone",
  "Singapour",
  "Slovaquie",
  "Slovénie",
  "Somalie",
  "Soudan",
  "Soudan du Sud",
  "Sri Lanka",
  "Suède",
  "Suisse",
  "Suriname",
  "Swaziland",
  "Syrie",
  "Tadjikistan",
  "Taïwan",
  "Tanzanie",
  "Tchad",
  "Thaïlande",
  "Timor-Oriental",
  "Togo",
  "Tonga",
  "Trinité-et-Tobago",
  "Tunisie",
  "Turquie",
  "Turkménistan",
  "Tuvalu",
  "Ukraine",
  "Uruguay",
  "Vanuatu",
  "Venezuela",
  "Vietnam",
  "Yémen",
  "Zambie",
  "Zimbabwe",
];

export const typeFormation = [
  {
    key: "1",
    value: "pmo",
    label: "Mise en place et gestion des bureaux de projet (PMO)",
  },
  {
    key: "2",
    value: "digital",
    label: "Monter un projet digital pour le service public",
  },
  { key: "3",
  value: "agriculture",
  label: "Agriculture numérique : enjeux, démarche, bonnes pratiques et Success Stories",
},
{ key: "4",
value: "integration",
label: "L'intégration des stratégies de l'économie verte dans les politiques de développement",
},
{ key: "5",
value: "energy",
label: "Conduite de projets énergies renouvelables et efficacité énergétique",
},
{ key: "6",
value: "climat",
label: "Outils de financement pour lutter contre les changements climatiques",
},
{ key: "6",
value: "PAN",
label: "Formulation et mise en œuvre d'un Plan d'Adaptation National PAN",
},

];
export const civilitie = [
  {
    key: "1",
    value: "female",
    label: "Madame",
  },
  {
    key: "2",
    value: "male",
    label: "Monsieur",
  },
];
export const HandicapBolean = [
  {
    key: "1",
    value: true,
    label: "Oui",
  },
  {
    key: "2",
    value: false,
    label: "Non",
  },
];
export const formatLabel = (x) => (
  <p>
    {x}
    <span style={{ color: "red" }}>*</span>
  </p>
);
