import React from "react";
import "./style404.css";
const Page404 = () => {
  var rows = [];
  for (let i = 0; i < 2; i++) {
    rows.push("stars");
  }
  console.log(rows);
  return (
    <div className="wrapperr">
      <div className="text_group">
        <p className="text_404">404</p>
        <p className="text_lost">
          La page que vous essayez d'atteindre a été perdue <br />
           lors de ce voyage dans l'espace.
        </p>
      </div>
      <div className="window_group">
        <div className="window_404">
          {rows.map((star, i) => (
            <div className={star}></div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Page404;
