// import { Route, Routes } from "react-router-dom";
import { BrowserRouter, Routes, Route } from "react-router-dom";

//* pages
import React from "react";
import ComingSoon from "./pages/ComingSoon";
import "./App.css";
import FormRegistration from "./ui-component/extends/Form/FormRegistration";
import Page404 from "./pages/404";

function App() {
  return (
    <>
      {/* <Menu /> */}
      <BrowserRouter>
        <Routes>
          <Route exact path="/" element={<ComingSoon />} />
          <Route exact path="/inscription" element={<FormRegistration />} />
          <Route path="*" element={<Page404 />} />
        </Routes>
        {/* <ComingSoon /> */}
        {/* <FormRegistration /> */}
      </BrowserRouter>
    </>
  );
}

export default App;
