import React from "react";
import cspImage from "../../assets/img-csp.png";
import logo from "../../assets/logo-AID-4.png";
import "./style.css";

import { BsLinkedin, BsFacebook } from "react-icons/bs";
import { SiGmail } from "react-icons/si";

import { Link } from "react-router-dom";
const index = () => {
  return (
    <>
      <div id="stars"></div>
          <div id="stars2"></div>
          <div id="stars3"></div>
      <div className="container">
        <div className="leftSide">
          <div className="logo">
            <img src={logo} alt="logo" />
          </div>
          <div className="content">
            <h1>Académie Internationale d’Innovation et de Développement</h1>
            <h6>Conduire le changement..</h6>
          </div>
          <div className="btnContainer">
            <p>
              L’Académie Internationale d’Innovation et de Développement offre
              des formations évolutives, interactives et opérationnelles
              parfaitement adaptées à vos besoins, projets et institutions.
            </p>
            <h3>- Bientôt disponible -</h3>
            <div className="btn">
              <p>
                Veuillez vous inscrire à nos prochaines formations. Nous vous
                contacterons sous peu avec de plus amples informations.
              </p>
              <Link to="/inscription" style={{ textDecoration: "none" }}>
                <button className="btn-gradient mr-4" href="/">
                  S'inscrire
                  {/* <ArrowRightAltIcon /> */}
                </button>
              </Link>
            </div>
          </div>
        </div>
        <div className="rightSide">
          <img src={cspImage} alt="" />
        </div>
      </div>
      <footer>
        <div className="copyright">
          <p>{`Copyright ©AID ${new Date().getFullYear()} --- Developed with `} <span className='redheart'> ♥ </span> by DataBerg</p>
        </div>
        <div className="icons">
          <ul className="wrapper">
            {/* <li className="icon facebook">
              <span className="tooltip">Facebook</span>
              <span>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.facebook.com/AIDformation/"
                >
                  <BsFacebook style={{ fontSize: "15px", color: "white" }} />
                </a>
              </span>
            </li> */}
            <li className="icon email">
              <span className="tooltip">Email</span>
              <span>
                <a href="mailto:contact@aidacademia.com">
                  <SiGmail style={{ fontSize: "15px", color: "white" }} />
                </a>
              </span>
            </li>
            <li className="icon linkedin">
              <span className="tooltip">Linkedin</span>
              <span>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.linkedin.com/in/aid-formation-acad%C3%A9mie-de-l-innovation-et-de-d%C3%A9veloppement-5a6814223"
                  style={{ textDecoration: "none" }}
                >
                  <BsLinkedin style={{ fontSize: "15px", color: "white" }} />
                </a>
              </span>
            </li>
          </ul>
        </div>
      </footer>
    </>
  );
};

export default index;
